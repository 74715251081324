<template>
    <nav class="quicklinks-list">
        <h3
            v-if="title"
            class="quicklink-list__title"
        >
            <slot name="title">{{ title }}</slot>
        </h3>

        <template v-for="(link, index) in links">
            <slot :link="link">
                <BaseQuicklink
                    :key="index"
                    :uri="getLink(link)"
                    :active="link.active"
                    :tag="link?.tag"
                >
                    {{ link.text }}
                </BaseQuicklink>
            </slot>
        </template>
    </nav>
</template>

<script setup lang="ts">

type Props = {
    title?: string;
    links: Array<any>;
}

const getLink = (link: any) => link.uri ?? link?.linkInternal?.[0]?.uri ?? link?.externalLink ?? link?.linkExternal;

defineProps<Props>();
</script>

<style lang="less">
.quicklink-list__title {
    @media @q-lg-min {
        margin-bottom: 1rem;
    }
}
</style>
